"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validationRules = void 0;
const firstNameRules = [(v) => !!v || 'Vorname ist erforderlich'];
const lastNameRules = [(v) => !!v || 'Nachname ist erforderlich'];
const emailRules = [
    (v) => !!v || 'E-Mail ist erforderlich',
    (v) => /.+@.+/.test(v) || 'E-Mail muss gültig sein',
    (v) => !/\s/.test(v) || 'E-Mail darf keine Leerzeichen enthalten',
    (v) => !/–/.test(v) || 'E-Mail darf keine ungültigen Unicode-Zeichen enthalten (–)',
];
const passwordRules = [
    (v) => v.length >= 10 || 'Passwort muss mindestens 10 Zeichen lang sein',
    (v) => /[A-Z]/.test(v) || 'Passwort muss mindestens einen Großbuchstaben enthalten',
    (v) => /[a-z]/.test(v) || 'Passwort muss mindestens einen Kleinbuchstaben enthalten',
    (v) => /[0-9]/.test(v) || 'Passwort muss mindestens eine Zahl enthalten',
];
exports.validationRules = {
    firstName: firstNameRules,
    lastName: lastNameRules,
    email: emailRules,
    password: passwordRules,
    minLength: (min) => (v) => v.length >= min || `Mindestens ${min} Zeichen`,
};
