"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.profilColumnsDefs = void 0;
const common_ui_1 = require("@rose/common-ui");
const authStore_1 = require("@/state/authStore");
function profilColumnsDefs() {
    return [
        {
            headerName: 'Info',
            field: 'device',
            flex: 1,
            resizable: true,
        },
        {
            headerName: 'Ausgestellt am',
            field: 'issuedAt',
            valueFormatter: common_ui_1.dateTimeFormatter,
            sortable: true,
            sort: 'desc',
            width: 180,
            resizable: true,
        },
        {
            headerName: 'Ungültig ab',
            field: 'expiresAt',
            valueFormatter: common_ui_1.dateTimeFormatter,
            sortable: true,
            width: 180,
            resizable: true,
        },
        {
            headerName: '',
            field: 'extid',
            cellRenderer: 'DeleteButtonCell',
            width: 40,
            cellStyle: { justifyContent: 'center' },
            cellRendererParams: {
                context: {
                    async clicked(field) {
                        await authStore_1.authStore.dispatch.removeToken(field.row);
                    },
                },
            },
        },
    ];
}
exports.profilColumnsDefs = profilColumnsDefs;
